<template>
  <div>
    <div class="row">
      <v-col cols="12" sm="3">
        <StatsCard
          title="SALES 24 HOURS"
          type="gradient-red"
          :subTitle="
            lastDayPurchase.reduce((a, b) => a + b.total, 0) | formatMoney
          "
          icon="ni ni-active-40"
        >
          <template slot="footer">
            <span class="text-nowrap">
              <b style="color: yellow">Chip: </b>
              $
              {{
                lastDayPurchase.reduce((a, b) => a + b.chipTotal, 0)
                  | formatMoney
              }}
            </span>
            <span class="text-nowrap">
              <b style="color: yellow"> Gold: </b>
              $
              {{
                lastDayPurchase.reduce((a, b) => a + b.goldTotal, 0)
                  | formatMoney
              }}
            </span>
            <span class="text-nowrap">
              <b style="color: yellow"> Multi: </b>
              $
              {{
                lastDayPurchase.reduce((a, b) => a + b.multiTotal, 0)
                  | formatMoney
              }}
            </span>
            <span class="text-nowrap">
              <b style="color: yellow"> Noel: </b>
              $
              {{
                lastDayPurchase.reduce((a, b) => a + b.noelTotal, 0)
                  | formatMoney
              }}
            </span>
            <br />
            <span class="text-nowrap">
              <b style="color: yellow">Total Order: </b>
              {{ lastDayPurchase.reduce((a, b) => a + b.totalOrder, 0) }}
            </span>
            <span class="text-nowrap">
              <b style="color: yellow"> Total Uniq Users: </b>
              {{ lastDayPurchase.reduce((a, b) => a + b.uniqueUserCount, 0) }}
            </span>
            <br />

            <span
              class="text-nowrap"
              :key="index"
              v-for="(item, index) in lastDayPurchase"
            >
              <b style="color: yellow"> {{ item.name }}:</b> {{ item.total }}
            </span>
            <br />
            <span
              :class="
                percentageCalc(lastDayPurchaseCompare, lastDayPurchase) > 0
                  ? 'text-success mr-2'
                  : 'text-danger mr-2'
              "
              >{{
                percentageCalc(lastDayPurchaseCompare, lastDayPurchase)
              }}%</span
            >
            <span class="text-nowrap"
              >Since last day $ ({{
                lastDayPurchaseCompare.reduce((a, b) => a + b.total, 0)
                  | formatMoney
              }})</span
            >
          </template>
        </StatsCard>
      </v-col>
      <v-col cols="12" sm="3">
        <StatsCard
          title="SALES 7 DAYS"
          type="gradient-red"
          :subTitle="
            lastWeekPurchase.reduce((a, b) => a + b.total, 0) | formatMoney
          "
          icon="ni ni-active-40"
        >
          <template slot="footer">
            <span class="text-nowrap">
              <b style="color: yellow">Chip: </b>
              $
              {{
                lastWeekPurchase.reduce((a, b) => a + b.chipTotal, 0)
                  | formatMoney
              }}
            </span>
            <span class="text-nowrap">
              <b style="color: yellow"> Gold: </b>
              $
              {{
                lastWeekPurchase.reduce((a, b) => a + b.goldTotal, 0)
                  | formatMoney
              }}
            </span>
            <span class="text-nowrap">
              <b style="color: yellow"> Multi: </b>
              $
              {{
                lastWeekPurchase.reduce((a, b) => a + b.multiTotal, 0)
                  | formatMoney
              }}
            </span>
            <span class="text-nowrap">
              <b style="color: yellow"> Noel: </b>
              $
              {{
                lastWeekPurchase.reduce((a, b) => a + b.noelTotal, 0)
                  | formatMoney
              }}
            </span>
            <br />
            <span class="text-nowrap">
              <b style="color: yellow">Total Order: </b>
              {{ lastWeekPurchase.reduce((a, b) => a + b.totalOrder, 0) }}
            </span>
            <span class="text-nowrap">
              <b style="color: yellow"> Total Uniq Users: </b>
              {{ lastWeekPurchase.reduce((a, b) => a + b.uniqueUserCount, 0) }}
            </span>
            <br />
            <span
              class="text-nowrap"
              :key="index"
              v-for="(item, index) in lastWeekPurchase.filter(
                (m) => m.total > 0
              )"
            >
              <b style="color: yellow"> {{ item.name }}:</b>
              {{ item.total.toFixed(2) }}
            </span>
            <br />
            <span
              :class="
                percentageCalc(lastWeekPurchaseCompare, lastWeekPurchase) > 0
                  ? 'text-success mr-2'
                  : 'text-danger mr-2'
              "
              >{{
                percentageCalc(lastWeekPurchaseCompare, lastWeekPurchase)
              }}%</span
            >
            <span class="text-nowrap"
              >Since last week $ ({{
                lastWeekPurchaseCompare.reduce((a, b) => a + b.total, 0)
                  | formatMoney
              }})</span
            >
          </template>
        </StatsCard>
      </v-col>
      <v-col cols="12" sm="3">
        <StatsCard
          title="SALES 15 DAYS"
          type="gradient-red"
          :subTitle="
            last2WeekPurchase.reduce((a, b) => a + b.total, 0) | formatMoney
          "
          icon="ni ni-active-40"
        >
          <template slot="footer">
            <span class="text-nowrap">
              <b style="color: yellow">Chip: </b>
              $
              {{
                last2WeekPurchase.reduce((a, b) => a + b.chipTotal, 0)
                  | formatMoney
              }}
            </span>
            <span class="text-nowrap">
              <b style="color: yellow"> Gold: </b>
              $
              {{
                last2WeekPurchase.reduce((a, b) => a + b.goldTotal, 0)
                  | formatMoney
              }}
            </span>
            <span class="text-nowrap">
              <b style="color: yellow"> Multi: </b>
              $
              {{
                last2WeekPurchase.reduce((a, b) => a + b.multiTotal, 0)
                  | formatMoney
              }}
            </span>
            <span class="text-nowrap">
              <b style="color: yellow"> Noel: </b>
              $
              {{
                last2WeekPurchase.reduce((a, b) => a + b.noelTotal, 0)
                  | formatMoney
              }}
            </span>
            <br />
            <span class="text-nowrap">
              <b style="color: yellow">Total Order: </b>
              {{ last2WeekPurchase.reduce((a, b) => a + b.totalOrder, 0) }}
            </span>
            <span class="text-nowrap">
              <b style="color: yellow"> Total Uniq Users: </b>
              {{ last2WeekPurchase.reduce((a, b) => a + b.uniqueUserCount, 0) }}
            </span>
            <br />
            <span
              class="text-nowrap"
              :key="index"
              v-for="(item, index) in last2WeekPurchase.filter(
                (m) => m.total > 0
              )"
            >
              <b style="color: yellow"> {{ item.name }}:</b>
              {{ item.total.toFixed(2) }}
            </span>
            <br />
            <span
              :class="
                percentageCalc(last2WeekPurchaseCompare, last2WeekPurchase) > 0
                  ? 'text-success mr-2'
                  : 'text-danger mr-2'
              "
              >{{
                percentageCalc(last2WeekPurchaseCompare, last2WeekPurchase)
              }}%</span
            >
            <span class="text-nowrap"
              >Since last 15 week $ ({{
                last2WeekPurchaseCompare.reduce((a, b) => a + b.total, 0)
                  | formatMoney
              }})</span
            >
          </template>
        </StatsCard>
      </v-col>
      <v-col cols="12" sm="3">
        <StatsCard
          title="SALES 30 DAYS"
          type="gradient-red"
          :subTitle="
            lastMonthPurchase.reduce((a, b) => a + b.total, 0) | formatMoney
          "
          icon="ni ni-active-40"
        >
          <template slot="footer">
            <span class="text-nowrap">
              <b style="color: yellow">Chip: </b>
              $
              {{
                lastMonthPurchase.reduce((a, b) => a + b.chipTotal, 0)
                  | formatMoney
              }}
            </span>
            <span class="text-nowrap">
              <b style="color: yellow"> Gold: </b>
              $
              {{
                lastMonthPurchase.reduce((a, b) => a + b.goldTotal, 0)
                  | formatMoney
              }}
            </span>
            <span class="text-nowrap">
              <b style="color: yellow"> Multi: </b>
              $
              {{
                lastMonthPurchase.reduce((a, b) => a + b.multiTotal, 0)
                  | formatMoney
              }}
            </span>
            <span class="text-nowrap">
              <b style="color: yellow"> Noel: </b>
              $
              {{
                lastMonthPurchase.reduce((a, b) => a + b.noelTotal, 0)
                  | formatMoney
              }}
            </span>
            <br />
            <span class="text-nowrap">
              <b style="color: yellow">Total Order: </b>
              {{ lastMonthPurchase.reduce((a, b) => a + b.totalOrder, 0) }}
            </span>
            <span class="text-nowrap">
              <b style="color: yellow"> Total Uniq Users: </b>
              {{ lastMonthPurchase.reduce((a, b) => a + b.uniqueUserCount, 0) }}
            </span>
            <br />
            <span
              class="text-nowrap"
              :key="index"
              v-for="(item, index) in lastMonthPurchase.filter(
                (m) => m.total > 0
              )"
            >
              <b style="color: yellow"> {{ item.name }}:</b>
              {{ item.total.toFixed(2) }}
            </span>
            <br />
            <span
              :class="
                percentageCalc(lastMonthPurchaseCompare, lastMonthPurchase) > 0
                  ? 'text-success mr-2'
                  : 'text-danger mr-2'
              "
              >{{
                percentageCalc(lastMonthPurchaseCompare, lastMonthPurchase)
              }}%</span
            >
            <span class="text-nowrap"
              >Since last month $ ({{
                lastMonthPurchaseCompare.reduce((a, b) => a + b.total, 0)
                  | formatMoney
              }})</span
            >
          </template>
        </StatsCard>
      </v-col>
    </div>
    <DetailCard search="" />
  </div>
</template>
<script>
import StatsCard from "@/components/StatsCard";
import DetailCard from "./components/DetailCard";

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { StatsCard, DetailCard },
  data() {
    return {
      purchase: [],
      dialog: false,
      lastDayPurchase: [],
      lastDayPurchaseCompare: [],
      lastWeekPurchase: [],
      lastWeekPurchaseCompare: [],
      last2WeekPurchase: [],
      last2WeekPurchaseCompare: [],
      lastMonthPurchase: [],
      lastMonthPurchaseCompare: [],
      currentPage: 1,
      search: "",
      purchases: {},
      currenDate: new Date(),
      //   new Date().getTime() + new Date().getTimezoneOffset() * 60000
      // ),
    };
  },
  methods: {
    percentageCalc(a, b) {
      return (
        (b.reduce((a, b) => a + b.total, 0) /
          a.reduce((a, b) => a + b.total, 0)) *
          100 -
        100
      ).toFixed(2);
    },
  },
  async mounted() {
    this.$store.dispatch("purchases/getCity");
    this.$store.dispatch("purchases/getCountry");
    this.$store
      .dispatch("purchases/getPlatformTotalSales", {
        startDate: Math.round(this.currenDate.addDays(-1).getTime() / 1000),
        endDate: Math.round(this.currenDate.getTime() / 1000),
      })
      .then((m) => (this.lastDayPurchase = m));
    this.$store
      .dispatch("purchases/getPlatformTotalSales", {
        startDate: Math.round(this.currenDate.addDays(-2).getTime() / 1000),
        endDate: Math.round(this.currenDate.addDays(-1).getTime() / 1000),
      })
      .then((m) => (this.lastDayPurchaseCompare = m));
    this.$store
      .dispatch("purchases/getPlatformTotalSales", {
        startDate: Math.round(this.currenDate.addDays(-7).getTime() / 1000),
        endDate: Math.round(this.currenDate.getTime() / 1000),
      })
      .then((m) => (this.lastWeekPurchase = m));
    this.$store
      .dispatch("purchases/getPlatformTotalSales", {
        startDate: Math.round(this.currenDate.addDays(-14).getTime() / 1000),
        endDate: Math.round(this.currenDate.addDays(-7).getTime() / 1000),
      })
      .then((m) => (this.lastWeekPurchaseCompare = m));
    this.$store
      .dispatch("purchases/getPlatformTotalSales", {
        startDate: Math.round(this.currenDate.addDays(-15).getTime() / 1000),
        endDate: Math.round(this.currenDate.getTime() / 1000),
      })
      .then((m) => (this.last2WeekPurchase = m));
    this.$store
      .dispatch("purchases/getPlatformTotalSales", {
        startDate: Math.round(this.currenDate.addDays(-30).getTime() / 1000),
        endDate: Math.round(this.currenDate.addDays(-15).getTime() / 1000),
      })
      .then((m) => (this.last2WeekPurchaseCompare = m));
    this.$store
      .dispatch("purchases/getPlatformTotalSales", {
        startDate: Math.round(this.currenDate.addDays(-30).getTime() / 1000),
        endDate: Math.round(this.currenDate.getTime() / 1000),
      })
      .then((m) => (this.lastMonthPurchase = m));
    this.$store
      .dispatch("purchases/getPlatformTotalSales", {
        startDate: Math.round(this.currenDate.addDays(-60).getTime() / 1000),
        endDate: Math.round(this.currenDate.addDays(-30).getTime() / 1000),
      })
      .then((m) => (this.lastMonthPurchaseCompare = m));
  },
};
</script>
